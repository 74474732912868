<template>
  <div>
    <div class="grid grid-cols-2 gap-4">
      <div class="vs-component vs-con-input-label vs-input w-full vs-input-primary">
        <p class="mb-2 leading-tight">{{$t('First Name')}}</p>
        <input v-validate="'required|alpha'" name="first name" v-model="merchant.first_name" class="w-full vs-inputx vs-input--input signUp-input"/>
        <span class="text-danger text-sm" v-show="errors.has('first name')">{{ $t('Must contain alphabets only') }}</span>
      </div>
      <div class="vs-component vs-con-input-label vs-input w-full vs-input-primary">
        <p class="mb-2 leading-tight">{{$t('Last Name')}}</p>
        <input v-validate="'required|alpha'" name="last name" v-model="merchant.last_name" class="w-full vs-inputx vs-input--input signUp-input"/>
        <span class="text-danger text-sm" v-show="errors.has('last name')">{{ $t('Must contain alphabets only') }}</span>
      </div>
      <div class="col-span-2 vs-component vs-con-input-label vs-input w-full vs-input-primary">
        <p class="mb-2 leading-tight">{{$t('Email')}}</p>
        <input v-validate="'required|email'" name="email" v-model="merchant.email" class="w-full vs-inputx vs-input--input signUp-input"/>
        <span class="text-danger text-sm" v-show="errors.has('email')">{{ $t('Must be a valid email') }}</span>
      </div>
      <div class="col-span-2 vs-component vs-con-input-label vs-input w-full vs-input-primary">
        <p class="mb-2 leading-tight">{{$t('Phone Number')}}</p>
        <input v-validate="{ required: true, regex: /(^01[0,1,2,5]\d{8}$|^\u0660\u0661[\u0660,\u0661,\u0662,\u0665][\u0660-\u0669]{8}$)/ }" name="phone number" v-model="merchant.phone_number" class="w-full vs-inputx vs-input--input signUp-input"/>
        <span class="text-danger text-sm" v-show="errors.has('phone number')">{{ $t('Must be a valid mobile number') }}</span>
      </div>
      <div class="col-span-2 vs-component vs-con-input-label vs-input w-full vs-input-primary relative">
        <p class="mb-2 leading-tight">{{$t('Password')}}</p>
        <input ref="password" :type="showPassword ? 'text' : 'password'" v-validate="{ required: true, regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_])(?!.* ).{8,}$/ }" name="password" v-model="merchant.password" class="w-full vs-inputx vs-input--input signUp-input" />
        <span @click="showPassword = !showPassword" class="material-symbols-outlined text-lg absolute top-0 right-0 block mt-11 mr-3 text-right cursor-pointer">{{ showPassword ? 'visibility_off' : 'visibility' }}</span>
        <div class="mt-2 text-sm" v-if="merchant.password.length > 0">
          <p :class="/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_])(?!.* ).{8,}$/.test(merchant.password) ? 'text-success' : 'text-danger'">{{$t('password must contain')}}:</p>
          <p :class="/[A-Z]/.test(merchant.password) ? 'text-success' : 'text-danger'" class="flex items-center">
            <feather-icon class="w-4 h-4 mr-4" :icon="/[A-Z]/.test(merchant.password) ? 'CheckIcon' : 'XIcon'"/>
            {{$t('At least one capital letter')}}
          </p>
          <p :class="/[a-z]/.test(merchant.password) ? 'text-success' : 'text-danger'" class="flex  items-center">
            <feather-icon class="w-4 h-4 mr-4" :icon="/[a-z]/.test(merchant.password) ? 'CheckIcon' : 'XIcon'"/>
            {{$t('At least one small letter')}}
          </p>
          <p :class="/\d/.test(merchant.password) ? 'text-success' : 'text-danger'" class="flex items-center">
            <feather-icon class="w-4 h-4 mr-4" :icon="/\d/.test(merchant.password) ? 'CheckIcon' : 'XIcon'"/>
            {{$t('At least one number')}}
          </p>
          <p :class="/[\W_]/.test(merchant.password) ? 'text-success' : 'text-danger'" class="flex items-center">
            <feather-icon class="w-4 h-4 mr-4" :icon="/[\W_]/.test(merchant.password) ? 'CheckIcon' : 'XIcon'"/>
            {{$t('At least one special characters:')}}
            <span class="text-grey-dark ml-1">{{'~`! @#$%^&*()-_+={}[]|;:">,./?'}}</span>
          </p>
          <p :class="merchant.password.length >= 8 ? 'text-success' : 'text-danger'" class="flex items-center">
            <feather-icon class="w-4 h-4 mr-4" :icon="merchant.password.length >= 8 ? 'CheckIcon' : 'XIcon'"/>
            {{$t('Password must be at least 8 characters')}}
          </p>
        </div>
      </div>
      <div class="col-span-2 vs-component vs-con-input-label vs-input w-full vs-input-primary">
        <p class="mb-2 leading-tight">{{$t('Confirm Password')}}</p>
        <input :type="showConfirmPassword ? 'text' : 'password'" v-validate="{ required: true, confirmed:'password' ,regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?!.* ).{8,}$/ }" name="confirm password" v-model="merchant.confirm_password" class="w-full vs-inputx vs-input--input signUp-input" data-vv-as="password" />
        <span @click="showConfirmPassword = !showConfirmPassword" class="material-symbols-outlined text-lg absolute top-0 right-0 block mt-11 mr-3 text-right cursor-pointer">{{ showConfirmPassword ? 'visibility_off' : 'visibility' }}</span>
        <span class="text-danger text-sm" v-show="errors.has('confirm password')">{{$t('Passwords don’t match')}}</span>
      </div>
    </div>
    <vue-recaptcha class="mt-8 flex justify-center m-auto" @verify="verfitication" :sitekey="siteKey"></vue-recaptcha>
    <div class="mt-6 w-full">
      <button @click="verifyNumber" class="w-full btn p-4">{{ $t('Start Shipping!') }}</button>
    </div>
    <div class="mt-3">
      <span class="text-sm flex justify-end">
        <a :href="releaseVersion === 'staging' ? 'https://app.staging.shipblu.com/' : 'https://app.shipblu.com'">{{$t('Have an account? Sign in')}}</a>
      </span>
    </div>
    <otp-verification :errorMessage="errorMessage" :merchant="merchant" :pinValue="pinValue" :pinModal="pinModal" @addMerchant="addMerchant" @pinModal="pinModal = $event" @pinValue="pinValue = $event"></otp-verification>
  </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha'
import OtpVerification from '../../merchant/components/OtpVerification.vue'
import i18nData from '../../../i18n/i18nData'
import {sendRequest} from '../../../http/axios/requestHelper'
import common from '../../../assets/utils/common'
import auth0 from 'auth0-js'

export default {
  props: ['merchant'],
  data () {
    return {
      releaseVersion: process.env.VUE_APP_RELEASE_VERSION,
      unableRecaptcha: false,
      pinModal: false,
      siteKey: process.env.VUE_APP_RECAPTCHA_KEY,
      errorMessage: '',
      pinValue: '',
      successPin: '',
      verificationPass : false,
      showPassword: false,
      showConfirmPassword: false
    }
  },
  watch: {
    successPin (val) {
      if (val === true) {
        this.pinModal = false
        this.errorMessage = ''
        this.payoutModal = false
      }
    },
    pinModal (val) {
      if (val === true) {
        this.errorMessage = ''
      }
    }
  },
  components: {
    VueRecaptcha,
    OtpVerification
  },                                                                                                                                                                                                                                                                                                            
  methods: {
    verfitication () {
      this.verificationPass = true
    },
    verifyNumber () {
      this.$validator.validateAll().then(result => {
        if (result && this.verificationPass) {
          sendRequest(false, false, this, 'api/v1/self-signup/otp/', 'post', {phone_number: this.merchant.phone_number, email: this.merchant.email}, false,
            () => {
              this.pinModal = true
            }, (error) => {
              if (error.response && error.response.data && error.response.data.details === 'You already have active OTP') {
                this.pinModal = true
              }
              common.notifyResponseError(this, error)
              this.$vs.loading.close()
            })
        }
      })
    },
    addMerchant () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.successPin = true
          this.errorMessage = ''
          this.merchant = {
            ...this.merchant,
            otp: String(this.pinValue),
            store_email: this.merchant.email,
            store_phone: this.merchant.phone_number
          }
          sendRequest(false, false, this, 'api/v1/self-signup/', 'post', this.merchant, false,
            () => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Merchant'] + i18nData[this.$i18n.locale][' has been created successfully!'],
                position: 'top-center'
              })
              this.pinValue = ''
              const webAuth = new auth0.WebAuth({
                domain: process.env.VUE_APP_AUTH0_DOMAIN,
                redirectUri: `${window.location.origin}/${this.$cookies.get('language') ? this.$cookies.get('language') : 'ar'}/auth-callback`,
                clientID: process.env.VUE_APP_AUTH0_CLIENT_ID,
                audience: process.env.VUE_APP_AUTH0_AUDIENCE,
                responseType: 'code token id_token',
                scope: 'openid profile email'
              })
              webAuth.login({
                realm: 'Username-Password-Authentication',
                username: this.merchant.email,
                password: this.merchant.password
              })
            }, (error) => {
              this.pinValue = ''
              this.$vs.loading.close()
              common.notifyResponseError(this, error)
            })
        }
      })
    }
  }
}
</script>

<style>
.signUp-input {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 12px 8px !important;
  line-height: 16px;
  height: 42.5px;
}
</style>